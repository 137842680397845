/**
* Wallop.js
*
* @fileoverview Minimal JS library to show & hide things
*
* @author Pedro Duarte
* @author http://pedroduarte.me/wallop
*
*/
(function(global){
  function Wallop(selector, options) {
    if (!selector) { throw new Error('Missing selector. Refer to Usage documentation: https://github.com/peduarte/wallop#javascript'); }

    this.options = {
      buttonPreviousClass: 'Wallop-buttonPrevious',
      buttonNextClass: 'Wallop-buttonNext',
      itemClass: 'Wallop-item',
      currentItemClass: 'Wallop-item--current',
      showPreviousClass: 'Wallop-item--showPrevious',
      showNextClass: 'Wallop-item--showNext',
      hidePreviousClass: 'Wallop-item--hidePrevious',
      hideNextClass: 'Wallop-item--hideNext',
      carousel: true
    };

    // Whitelist elements which contain `length`
    this.whitelist = {
      'form': true
    };

    if (selector.length > 0 && !this.whitelist[selector]) {
      throw new Error('Selector cannot be an array, Refer to Usage documentation: https://github.com/peduarte/wallop#javascript');
    } else {
      this.$selector = selector;
    }

    this.options = extend(this.options, options);
    this.event = null;

    // "Global vars"
    this.reset();
    this.buttonPrevious = this.$selector.querySelector(' .' + this.options.buttonPreviousClass);
    this.buttonNext = this.$selector.querySelector(' .' + this.options.buttonNextClass);

    this.bindEvents();
    this.createCustomEvent();

    // If there is no active item, start at 0
    if (this.currentItemIndex === -1) {
      this.currentItemIndex = 0;
      addClass(this.allItemsArray[this.currentItemIndex], this.options.currentItemClass);
    }

    // Update button states to make sure the correct state is set on initialization
    this.updateButtonStates();

    // Wrapped in timeout function so event can
    // be listened from outside at anytime
    var _this = this;
    setTimeout(function() {
      _this.event.detail.currentItemIndex = _this.currentItemIndex;
      _this.$selector.dispatchEvent(_this.event);
    }, 0);
  }

  var selectorPool = [];

  var WS = Wallop.prototype;

  // Update prev/next disabled attribute
  WS.updateButtonStates = function () {
    if ((!this.buttonPrevious && !this.buttonNext) || this.options.carousel) { return; }

    if (this.currentItemIndex === this.lastItemIndex) {
      this.buttonNext.setAttribute('disabled', 'disabled');
    } else if (this.currentItemIndex === 0) {
      this.buttonPrevious.setAttribute('disabled', 'disabled');
    }
  };

  // Reset all settings by removing classes and attributes added by goTo() & updateButtonStates()
  WS.removeAllHelperSettings = function () {
    removeClass(this.allItemsArray[this.currentItemIndex], this.options.currentItemClass);
    removeClass($$(this.options.hidePreviousClass, this.$selector), this.options.hidePreviousClass);
    removeClass($$(this.options.hideNextClass, this.$selector), this.options.hideNextClass);
    removeClass($$(this.options.showPreviousClass, this.$selector), this.options.showPreviousClass);
    removeClass($$(this.options.showNextClass, this.$selector), this.options.showNextClass);

    if (!this.buttonPrevious && !this.buttonNext) { return; }

    this.buttonPrevious.removeAttribute('disabled');
    this.buttonNext.removeAttribute('disabled');
  };

  // Method to add classes to the right elements depending on the index passed
  WS.goTo = function (index, direction, force) {
    if (index === this.currentItemIndex && (typeof force === 'undefined' || !force)) { return; }

    // Fix the index if it's out of bounds and carousel is enabled
    index = index === -1 && this.options.carousel ? this.lastItemIndex : index;
    index = index === this.lastItemIndex + 1 && this.options.carousel ? 0 : index;

    // Exit when index is out of bounds
    if (index < 0 || index > this.lastItemIndex) { return; }

    this.removeAllHelperSettings();

    var isForwards = (index > this.currentItemIndex || index === 0 && this.currentItemIndex === this.lastItemIndex) && !(index === this.lastItemIndex && this.currentItemIndex === 0);

    // if direction is defined, force a particular direction (fixes EXEV-297)
    if(direction) {
      if(direction === 'next') {
        isForwards = true;
      } else if (direction === 'previous'){
        isForwards = false;
      }
    }

    addClass(this.allItemsArray[this.currentItemIndex], isForwards ? this.options.hidePreviousClass : this.options.hideNextClass);
    addClass(this.allItemsArray[index], this.options.currentItemClass + ' ' + (isForwards ? this.options.showNextClass : this.options.showPreviousClass));

    this.currentItemIndex = index;

    this.updateButtonStates();

    this.event.detail.currentItemIndex = this.currentItemIndex;
    this.$selector.dispatchEvent(this.event);
  };

  // Previous item handler
  WS.previous = function () {
    this.goTo(this.currentItemIndex - 1, 'previous');
  };

  // Next item handler
  WS.next = function () {
    this.goTo(this.currentItemIndex + 1, 'next');
  };

  // Update global variables
  WS.reset = function () {
    this.allItemsArray = Array.prototype.slice.call(this.$selector.querySelectorAll(' .' + this.options.itemClass));
    this.currentItemIndex = this.allItemsArray.indexOf(this.$selector.querySelector(' .' + this.options.currentItemClass));
    this.lastItemIndex = this.allItemsArray.length - 1;
  };

  // Attach click handlers
  WS.bindEvents = function () {
    selectorPool.push(this.$selector);

    var _this = this;

    if (this.buttonPrevious) {
      this.buttonPrevious.addEventListener('click', function (event) {
        event.preventDefault();
        _this.previous();
      });
    }

    if (this.buttonNext) {
      this.buttonNext.addEventListener('click', function (event) {
        event.preventDefault();
        _this.next();
      });
    }

  };

  // Method to bind custom event
  WS.on = function (eventName, callback) {
    this.$selector.addEventListener(eventName, callback, false);
  };

  // Method to unbind custom event
  WS.off = function (eventName, callback) {
    this.$selector.removeEventListener(eventName, callback, false);
  };

  // Create custom Event
  WS.createCustomEvent = function () {
    var _this = this;
    this.event = new CustomEvent('change', {
      detail: {
        wallopEl: _this.$selector,
        currentItemIndex: Number(_this.currentItemIndex)
      },
      bubbles: true,
      cancelable: true
    });
  };

  // Helper functions
  function $$(element, container) {
    if (!element) { return; }
    if (!container) {
      container = document;
    }
    return container.querySelector('.' + element);
  }

  function addClass(element, className) {
    if (!element) { return; }
    element.className = (element.className + ' ' + className).trim();
  }

  function removeClass(element, className) {
    if (!element) { return; }
    element.className = element.className.replace(className, '').trim();
  }

  function extend(origOptions, userOptions){
    var extendOptions = {}, attrname;
    for (attrname in origOptions) { extendOptions[attrname] = origOptions[attrname]; }
    for (attrname in userOptions) { extendOptions[attrname] = userOptions[attrname]; }
    return extendOptions;
  }

  // Pollyfill for CustomEvent() Constructor - thanks to Internet Explorer
  // https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent#Polyfill
  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = window.CustomEvent ? window.CustomEvent.prototype : {};
  window.CustomEvent = CustomEvent;

  // Exports to multiple environments
  if(typeof define === 'function' && define.amd){ //AMD
    define(function () { return Wallop; });
  } else if (typeof module !== 'undefined' && module.exports){ //node
    module.exports = Wallop;
  } else { // browser
    // use string because of Google closure compiler ADVANCED_MODE
    /* jslint sub:true */
    global['Wallop'] = Wallop;
  }
}(this));

/*===========================
  Swipe-it v1.4.1
  An event listener for swiping gestures with vanilla js.
  https://github.com/tri613/swipe-it#readme

  @Create 2016/09/22
  @Update 2017/08/11
  @Author Trina Lu
  ===========================*/
'use strict';

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

(function (window, document, exportName) {

  var _target = false;
  var _window = [window];
  var defaultOptions = {
    'mouseEvent': true,
    'minDistance': 30
  };

  function SwipeIt(selector) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var _elements = nodeListToArray(document.querySelectorAll(selector));
    var _xStart = void 0,
        _yStart = void 0,
        _xEnd = void 0,
        _yEnd = void 0;

    options.mouseEvent = options.mouseEvent === undefined ? defaultOptions.mouseEvent : options.mouseEvent;
    options.minDistance = options.minDistance === undefined ? defaultOptions.minDistance : options.minDistance;

    init();
    ready();

    this.on = function (swipeEvent, callback) {
      listen(swipeEvent, callback, _elements);
      return this;
    };

    function ready() {
      //for touching device
      listen('touchstart', touchStartHandler, _elements);
      listen('touchmove', touchMoveHandler, _elements);
      listen('touchend', touchEndHandler, _elements);
      //for mouse
      if (options.mouseEvent) {
        listen('mousedown', mouseDownHandler, _elements);
      }
    }

    function init() {
      _xStart = false;
      _yStart = false;
      _xEnd = false;
      _yEnd = false;
      _target = false;
    }

    function mouseDownHandler(e) {
      _target = this;
      _xStart = e.clientX;
      _yStart = e.clientY;
      listen('mousemove', mouseMoveHandler, _window);
      listen('mouseup', mouseEndHandler, _window);
    }

    function mouseMoveHandler(e) {
      e.preventDefault(); // **prevent drag event from being triggered**
      if (!_xStart || !_yStart) return;
      _xEnd = e.clientX;
      _yEnd = e.clientY;
    }

    function mouseEndHandler(e) {
      stopListen('mousemove', mouseMoveHandler, _window);
      stopListen('mouseup', mouseEndHandler, _window);
      touchEndHandler(e);
    }

    function touchStartHandler(e) {
      _target = this;
      _xStart = e.touches[0].clientX;
      _yStart = e.touches[0].clientY;
    }

    function touchMoveHandler(e) {
      _xEnd = e.touches[0].clientX;
      _yEnd = e.touches[0].clientY;
    }

    function touchEndHandler(e) {
      if (_xStart && _yStart && _xEnd && _yEnd) {
        var disH = _xStart - _xEnd,
            disV = _yStart - _yEnd;

        var _map = [disH, disV].map(Math.abs),
            _map2 = _slicedToArray(_map, 2),
            h = _map2[0],
            v = _map2[1];

        var d = options.minDistance;
        if (h > d) {
          //horizontal
          var swipeEventString = _xStart < _xEnd ? 'swipeRight' : 'swipeLeft';
          triggerEvent(swipeEventString, _target, {
            distance: disH,
            start: _xStart,
            end: _xEnd
          });
        }
        if (v > d) {
          //vertical
          var _swipeEventString = _yStart > _yEnd ? 'swipeUp' : 'swipeDown';
          triggerEvent(_swipeEventString, _target, {
            distance: disV,
            start: _yStart,
            end: _yEnd
          });
        }
        if (h > d || v > d) {
          triggerEvent('swipe', _target);
        }
      };
      init();
    }
  }

  function listen(event, handler, elements) {
    toArray(elements).forEach(function (element) {
      return element.addEventListener(event, handler);
    });
  }

  function stopListen(event, handler, elements) {
    toArray(elements).forEach(function (element) {
      return element.removeEventListener(event, handler);
    });
  }

  function triggerEvent(eventString, elements) {
    if (!elements) { return; }
    var extraParams = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    var event = document.createEvent('Event');
    event.initEvent(eventString, true, true);
    event.swipe = extraParams;
    toArray(elements).forEach(function (element) {
      return element.dispatchEvent(event);
    });
  }

  function nodeListToArray(nodes) {
    var nodesArray = [];
    for (var i = 0; i < nodes.length; i++) {
      nodesArray.push(nodes[i]);
    }
    return nodesArray;
  }

  function toArray(elements) {
    return Array.isArray(elements) ? elements : [elements];
  }

  //export
  window[exportName] = SwipeIt;
})(window, document, 'SwipeIt');
